<template>
  <div
    style="background-color: #fff; padding: 10px 5px; width: 100%; height: 100%"
  >
    <el-table :data="tableData" stripe highlight-current-row>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="key" label="key"></el-table-column>
      <el-table-column prop="create_operator" label="创建者"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            :loading="scope.row.loading"
            @click="downLoadCrowed(scope.row)"
            size="mini"
            type="primary"
          >
            人群下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getAllCrowd } from "@/api/createCrowd";
import { mapGetters } from "vuex";
import { downloadCrowd } from "@/api/downloadCrowd";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
    };
  },
  async mounted() {
    let { errercode, data } = await getAllCrowd();
    if (errercode === 101) {
      data.forEach((item) => (item.loading = false));
      this.tableData = data;
    } else {
      this.$message.error("获取所有人群失败!");
    }
  },
  computed: {
    ...mapGetters(["month"]),
  },
  methods: {
    downLoadCrowed(scope) {
      scope.loading = true;
      let params = {
        seg: scope.key,
        month: this.month,
        seg_name: scope.name,
      };
      this.$message({
        message: "数据正在解析，请不要刷新切换页面，耐心等待!",
        type: "info",
        duration: 8000,
      });
      downloadCrowd(params, `${scope.name}.xlsx`).then((res) => {
        console.log(res);
        this.$message({
          message: "数据解析成功，正在下载",
          type: "success",
        });
        scope.loading = false;
      });
    },
  },
};
</script>

<style></style>
